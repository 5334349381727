import React, { useState, useEffect, useRef} from "react";

export default function Input({value,onChange, editingFlag, reference,   onClick}) {
    const [content, setContent] = useState(value);
    const [width, setWidth] = useState(0);
    const span = useRef();
    useEffect(() => {
        setWidth(span.current.offsetWidth);
        setContent(value);
    }, [value, content]);

    const changeHandler = evt => {
        setContent(evt.target.value);
    };

    return (
        <div id="custom">
            <span id="hide" ref={span} style={{marginRight: content.length ? 0 : 5}}>{content}</span>
            <input
                className='report-name-input'
                type="text"
                value={content}
                ref={reference}
                style={{ width: width + 10}}
                onClick={onClick}
                onChange={ event => {
                        changeHandler(event)
                        onChange(event.target.value)
                    }
                }
            />
            <span className='dots'>{(!editingFlag && width > 150) ? '...' : ''}</span>
        </div>
    );
};
