interface Ranges {
        [key: string]: string,
}
interface chartTypesInterface {
        [key: string]: string,
}
export class Constants {
        public static CATEGORIES:Array<string> = ['Revenue', 'Inventory', 'Data analytics', 'Campaign Delivery', 'Other'];
        public static RANGES:Ranges =
            {
                STATIC: 'Custom Range',
                YESTERDAY: 'Yesterday',
                LAST_7_DAYS: 'Last 7 days',
                LAST_30_DAYS: 'Last 30 days',
                LAST_3_MONTHS: 'Last 3 months',
                LAST_6_MONTHS: 'Last 6 months',
                LAST_3_YEARS: 'Last 3 years',
                LAST_WEEK: 'Last week',
                LAST_MONTH: 'Last month',
                LAST_QUARTER: 'Last quarter',
                LAST_YEAR: 'Last year',
                WEEK_TO_DATE: 'Week to date',
                MONTH_TO_DATE: 'Month to date',
                QUARTER_TO_DATE: 'Quarter to date',
                YEAR_TO_DATE: 'Year to date',
            };
        public static chartColors:Array<string> = ['#ED254E', '#F9DC5C', '#c2eabd', '#011936','#465362', '#563440','#7a3b69', '#9a879d','#cfcfcd', '#d7dedc'];
        public static reportsPerPage:Array<number> = [10, 20, 30, 50, 100];
        public static chartTypes:chartTypesInterface = {
                LINE: 'Line',
                PIE: 'Pie',
                CLUSTERED_COLUMN: 'Clustered Column',
                CLUSTERED_COLUMN_LINE: 'Clustered Column - Line',
                CLUSTERED_BAR: 'Clustered Bar',
                STACKED_BAR: 'Stacked Bar',
                BAR: 'Bar',
                NO_CHART: 'No Chart'
        }

}

