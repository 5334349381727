import React from "react";
import Utils from "../../../lib/utils/utils";
import './styles.scss';
export default class NotFound extends React.Component{
    render(){
        return (
            <div className='not-found'>
                <h2>
                    {Utils.translatedString('notFound')}
                </h2>
            </div>
        );
    }
}
