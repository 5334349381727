import React from "react";
import Utils from "../../../lib/utils/utils";
import {RiLoader5Line} from "react-icons/ri";
import {BsListUl} from "react-icons/bs";
interface Props {
    onClick: () => void,
    disabled: boolean,
    load: boolean,

}
export default function GenerateButton({onClick, disabled, load}: Props) {
    return (
        <button
            onClick={onClick}
            className='generate with-icon'
            disabled={disabled || load}
        >
            {load ? <div className='load-animation'><RiLoader5Line/></div> : <div className='btn-icon'><BsListUl/></div>}
            <span>{Utils.translatedString('controls.generate')}</span>
        </button>
    );
}
