import React from 'react';
import {RiLoader5Line} from "react-icons/ri";
import './styles.scss';
interface Props {
    size: number,
}
 export function Loader({size}:Props) {
    return <div className='load-animation'><RiLoader5Line size={size}/></div>
}

export default {Loader}
