import React from "react";
import Utils from "../../lib/utils/utils";
import './style.scss';

export default function Splashscreen() {
    return (
        <h2 className='splashscreen'>
            {Utils.translatedString('loading')}
        </h2>
    );
}
