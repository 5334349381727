import HttpUrls from "../interfaces/httpUrls";
import Request from "../interfaces/request";
import {
    USER_NAME_URL,
    USERS_LIST_URL,
    ACCESS_EXTENSION_URL,
    ACCESS_GAM_URL,
    IMPERSONATE_URL,
    UNIMPERSONATE_URL,
    IS_IMPERSONATED_URL,
} from "../config/urls";
import Utils from "../utils/utils";

export default class UserService extends HttpUrls{

    async getUsername(){
        return await new Request(USER_NAME_URL + this.uuid).http()
            .then(response => response ? response : '');
    }
    async getUsersList(uuid: string, networkId: string){
        return await new Request(`${USERS_LIST_URL}?uuid=${uuid}&networkId=${networkId}`).http()
            .then(response => {
                return response ? Utils.sort(JSON.parse(response), 'firstName') : []
            });
    }

    async checkAccessGAM(id: string) {
        return await new Request(ACCESS_GAM_URL + this.uuid + '&networkId=' + id).http()
            .then(response => response ? JSON.parse(response).accessStatus : false);
    }
    async checkAccessExtension(id: string) {
        return await new Request(ACCESS_EXTENSION_URL + this.uuid + '&networkId=' + id).http()
            .then(response => response ? JSON.parse(response).accessStatus : false);
    }
    async switchToAdOpsDashboard(email: string, networkId: string) {
        return await new Request(IMPERSONATE_URL + this.uuid + '&targetEmailAddress=' + email + '&networkId=' + networkId).http()
            .then(response => response);
    }

    async switchToPersonalDashboard() {
        return await new Request(UNIMPERSONATE_URL + this.uuid).http()
            .then(response => response);
    }
    async isImpersonated() {
        return await new Request(IS_IMPERSONATED_URL + this.uuid).http()
            .then(response => response === 'true');
    }
}

