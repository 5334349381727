import React from "react";
import {Link} from "react-router-dom";
import editIcon from "../../../../lib/assets/edit.svg";
import removeIcon from "../../../../lib/assets/remove.svg";
import parse from "html-react-parser";
import Utils from "../../../../lib/utils/utils";
import moment from "moment";
import {Constants} from "../../../../lib/config/constants";
import Input from "../../../templateAndReport/input";
import ReportService from "../../../../lib/services/reportService";

export default class ReportItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowEditReportName: false,
            reportName: props.item.reportName || '',
            item: props.item,
            savedReportName: props.item.reportName || '',
        }
        this.input = React.createRef();
    }
    componentDidMount() {
        let inputNode = this.input.current;
        inputNode && inputNode.addEventListener('keypress', (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                inputNode && inputNode.blur();
                this.renameReport();
                this.setState({allowEditReportName: false});
            }
        });
        document.body.addEventListener('click', ()=> {
            this.state.allowEditReportName && this.setState({
                reportName: this.state.savedReportName,
                allowEditReportName: false,
            })
        })

    }
    setReportName = (text) => {
        this.setState({reportName: text});
    }
    renameReport = async () => {
        const {item, reportName} = this.state;
        this.setState({
            allowEditReportName: false,
            savedReportName: reportName,
        });
        await new ReportService().changeReportName(item.id, reportName);
    }
    parseHTML = (svgString) => {
        const {item} = this.state;
        if(item.defaultChart === 'NO_CHART') {
            let html = parse(svgString);
            let table = React.Fragment;
            if(html) {
                html.props.children.forEach(htmlChild => {
                    if(htmlChild.type === 'body') {
                        htmlChild.props.children.forEach(bodyChild => {
                            if(bodyChild.type === 'table') {
                                table = bodyChild;
                            }
                        })
                    }
                })
            }
            return <div className='table-container'>{table}</div>;
        } else {
            return parse(svgString)
        }
    }
    render() {
        const { allowReportDelete} = this.props;
        const {item, allowEditReportName,reportName} = this.state;
        let from = item.dateRange?.startDate ? new Date(item.dateRange.startDate[0],item.dateRange.startDate[1] - 1,item.dateRange.startDate[2]) : null;
        let to = item.dateRange?.startDate ? new Date(item.dateRange.endDate[0],item.dateRange.endDate[1] - 1,item.dateRange.endDate[2]) : null;
        let svgString = item.status === 'READY' ?
            item.data.charts[item.defaultChart || 'LINE'] : '';
        return(<div className={`item ${item.defaultChart === 'NO_CHART' ? 'no-chart' : ''}`} key={item}>
            <div className="item-header">
                <h4 className='item-header-name'>
                    {item.template.name}
                    <div className="wrapper1" onClick={(e) => e.stopPropagation()}>
                        <Input
                            value={reportName}
                            reference={this.input}
                            onChange={(text) => this.setReportName(text)}
                            editingFlag={allowEditReportName}
                            onClick={() => this.setState({allowEditReportName: true})}

                        />
                        {allowEditReportName &&
                        <input type='submit' value='✓' onClick={()=> this.renameReport()}/>
                        }
                        <button
                            onClick={()=> {
                                this.setState({allowEditReportName: !allowEditReportName});
                                if(this.input !== null) {
                                    this.input.current.focus();
                                }
                            }}
                            style={{visibility: allowEditReportName ? 'hidden' : 'visible'}}
                            className={`edit ${allowEditReportName ? '' : 'with-margin'}`}
                        >
                            Edit
                        </button>
                    </div>
                </h4>
                <div className="item-header-controls">
                    <Link to={`/reports/${item.id}`}>
                        <img src={editIcon} alt="view/edit"/>
                    </Link>
                    <button onClick={() => allowReportDelete(item.id)}>
                        <img src={removeIcon} alt="remove"/>
                    </button>
                </div>
            </div>
            <div className="item-body">
                {item.status === 'READY' ?
                    this.parseHTML(svgString):
                    <div className='pending-text'>
                        <h3>{Utils.translatedString('messages.chartPlaceholder')}</h3>
                    </div>}

            </div>
            <div className="item-footer">
                {item.dateRange ? <div className='date-range'>
                    {Utils.translatedString('properties.range')}{': '}
                    <span>{moment(from).format('YYYY/MM/DD')}</span>
                    {' -- '}
                    <span>{moment(to).format('YYYY/MM/DD')}</span>
                    {' ('}{Constants.RANGES[item.dateRangeType]})
                </div> : null}
            </div>
        </div>)
    }
}
