import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from "../pages/dashboard";
import NotFound from "../pages/notFound";
import Template from "../pages/templatePage";
import Report from "../pages/reportPage";


class RoutesNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuState: false,
        }
    }
    onApply = (data) => {
        this.setState({menuState: data});
    };
    render(){
        return (
            <Routes>
                <Route path={"/dashboard"} element={<Dashboard state={this.state.menuState} currentNetwork={this.props.currentNetwork}/>}/>
                <Route path="/" element={<Navigate replace to={'/dashboard'} />} />
                <Route path="/templates/:id" element={<Template currentId={this.props.currentNetwork} logout={this.props.handleLogout} menuState={this.state.menuState}/>}/>
                <Route path="/reports/:id" element={<Report currentId={this.props.currentNetwork} logout={this.props.handleLogout} menuState={this.state.menuState}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        );
    }
}
export default RoutesNav

