import React, {ReactNode} from "react";
import i18n from "i18next";

interface Props {
    content: ReactNode,
    onApply: any,
    onCancel: any|null,
    applyTitle?: string,
    cancelTitle?: string,
    close?: boolean,
    onClose?: any,
}

export default function ModalWindow({content, onApply, onCancel, applyTitle = 'Ok', cancelTitle = i18n.t('controls.cancel'), close = false, onClose}:Props) {
    return (
        <div className='modal-wrapper'>
            <div className="modal-wrapper-content">
                {content}
                <div className="modal-wrapper-content-controls">
                    <button onClick={onApply}>
                        {applyTitle}
                    </button>
                    {onCancel && <button onClick={() => onCancel()}>{cancelTitle}</button>}
                    {close ? <button onClick={() => onClose()}>{i18n.t('controls.cancel')}</button> : null}
                </div>
            </div>
        </div>
    );
}
