import React from "react";
import Utils from "../../../lib/utils/utils";

interface Props {
    disabled: boolean,
}

export default function DownloadButton({disabled}: Props) {
    return (
        <span className={`download ${disabled ? 'disabled-link' : ''} with-icon`}
              onClick={() => window.print()}
        >
            {Utils.translatedString('controls.downloadReport')}
        </span>
    );
}
