import React, {createRef} from 'react';
import uploadIcon from "../../../lib/assets/upload.svg";

import {UPLOAD_TEMPLATE_URL} from "../../../lib/config/urls";
import axios from 'axios';
import {Constants} from "../../../lib/config/constants";
import Utils from "../../../lib/utils/utils";
import './styles.scss';
import CookieService from "../../../lib/services/cookieService";

interface Props {
    updateList: () => Promise<void>,
    currentNetwork: string,
}

interface State {
    isFileSelected: boolean,
    file: any,
    fileName: string,
    load: boolean,
    error: boolean,
    askCategorySelect: boolean,
    category: string,
}

export default class UploadButton extends React.Component<Props, State> {
    private readonly uploadButton = createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props);
        this.uploadButton = React.createRef();
        this.state = {
            fileName: '',
            file: {},
            isFileSelected: false,
            load: false,
            error: false,
            askCategorySelect: false,
            category: '',
        }
    }

    getFileName = (file: any) => {
        this.setState({
            file: file,
            isFileSelected: !!file.name,
            fileName: file.name,
            askCategorySelect: !!file.name,
        });
    }

    sendTemplate = (event:any) => {
        event.preventDefault();
        setTimeout(()=> {
            this.setState({
                isFileSelected: false,
                file: {},
            })
        }, 1000);
    }
    changeCategory = (value: string = '') => {
        this.setState({
            category: value,
        });
    }
    cancelSelect = () => {
        this.setState({
            askCategorySelect: false,
            isFileSelected: false,
            file: null,
        });
    }
    applyCategory = async () => {
        this.setState({
            askCategorySelect: false,
        });
        this.onSubmit();
    }
    onSubmit = () => {
        this.setState({
            load: true,
        })
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('name', this.state.fileName);
        formData.append('category', this.state.category);
        formData.append('networkId', this.props.currentNetwork);
        formData.append('uuid', CookieService.getCookie('uuid'));

        axios.post(UPLOAD_TEMPLATE_URL, formData)
            .then((response) => response)
            .then(() => {
                this.setState({
                    isFileSelected: false,
                    category: '',
                    fileName: '',
                    load: false,
                });
                this.props.updateList().then(()=>{});
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({error: true,
                    isFileSelected: false,
                    load: false,
                });
                setTimeout(() => this.setState({error: false}), 5000);
            });
    }
    renderCategoriesOptions = () => {
        return Constants.CATEGORIES.map((item, index) =>
            <div key={item + index} className='radio-container'>
                <input type="radio"
                       required={true}
                       id={`category-${index}`}
                       value={item}
                       name="category"
                       onChange={(e) => this.changeCategory(e.currentTarget.value)}
                />
                <label htmlFor={`category-${index}`} >{item}</label>
            </div>
        )
    }
    render(){
        const {isFileSelected, error, askCategorySelect} = this.state;
        return (
            <>
                <form action="" encType="multipart/form-data">
                    <input
                        ref={this.uploadButton}
                        name="file"
                        id="file"
                        className="inputfile title-as-btn"
                        type="file"
                        value=''
                        onClick={(e) => isFileSelected && this.sendTemplate(e)}
                        onChange={()=> this.getFileName(this.uploadButton?.current?.files?.[0] ?? '')}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <label htmlFor="file" className=' title-as-btn'>
                        <img src={uploadIcon} alt=""/>
                        <span>{Utils.translatedString('controls.upload')}</span> </label>
                    {error && <span style={{color: 'red'}}>
                        {Utils.translatedString('messages.uploadError')}
                    </span>}
                </form>
                {askCategorySelect && <div className="upload-modal">
                    <form
                        onSubmit={() =>this.applyCategory()}
                        className='upload-modal-body'>

                            <h3>{Utils.translatedString('messages.selectTemplateName')}</h3>
                            <div className="upload-modal-body-categories">
                                <input type="text"
                                       value={this.state.fileName}
                                       required={true}
                                       className='upload-modal-body-categories-input'
                                       onChange={(e) =>
                                           this.setState({
                                               fileName: e.target.value
                                           }) }/>
                            </div>
                            <h3>{Utils.translatedString('messages.selectTemplateCategory')}</h3>
                            <div className="upload-modal-body-categories">
                                {this.renderCategoriesOptions()}
                            </div>
                            <div className="modal-wrapper-content-controls">
                                <input type='submit' role='button' value='Ok'/>
                                <button onClick={() => this.cancelSelect()}>
                                    {Utils.translatedString('controls.cancel')}
                                </button>
                            </div>
                    </form>
                </div>}
            </>
        );
    }
}
