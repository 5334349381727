import React from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import ModalWindow from "../../common/modal";
import Utils from "../../../lib/utils/utils";
import DashboardButton from "../../templateAndReport/buttons/goBack";
import DownloadButton from "../../templateAndReport/buttons/downoad";
import SourceButton from "../../templateAndReport/buttons/viewSource";
import CookieService from "../../../lib/services/cookieService";
import RemoveButton from "../../templateAndReport/buttons/remove";
import ReportProperties from "../../templateAndReport/reportProperties";
import GenerateButton from "../../templateAndReport/buttons/generate";
import Input from "../../templateAndReport/input";
import editIcon from "../../../lib/assets/edit.svg";
import {Constants} from "../../../lib/config/constants";
import DateRange from "../../templateAndReport/dateRange/range";
import moment from "moment";
import ReportService from "../../../lib/services/reportService";

export default class Layout extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reportName: '',
            allowEditReportName: false,
            triggerMessageOvernight: false,
            filters: {
                range: {
                    value: {from: null,to: null, type: ''},
                },
                adOps: {value: []}
            },
            triggerRangeModal: false,
            triggerAdOpsModal: false,
            triggerMessageModal: false,
            properties: [],
            selectedChart: '',
            isGenerating: false,
            data: props.data,
            contentWidth: 0,
            localState: true,
        }
        this.contentRef = React.createRef();
        this.input = React.createRef();
    }
    componentDidMount() {
        this.setState({contentWidth: this.contentRef.current.getBoundingClientRect().width});
        window.addEventListener('resize', this.checkWindowWidth);
        let inputNode = this.input.current;
        inputNode && inputNode.addEventListener('keypress', (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                inputNode && inputNode.blur();
                this.setState({allowEditReportName: false});
            }
        });
            if(!this.props.data.properties || !Object.keys(this.props.data.properties).length) {
                this.setState({properties: []})
            } else {
                this.setState({properties: this.props.data.properties.chartTypes.split(', ')})
            }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            filters: {
                range: {
                    value: {from: null,to: null, type: ''},
                },
                adOps: {value: []}
            },
        });
    }

    checkWindowWidth = () => {
        const {localState} = this.state;
        this.setState({contentWidth: localState ? window.innerWidth - 80 : window.innerWidth - 240});
    }
    setReportName = (text) => {
        this.setState({reportName: text});
    }
    renderRenameInput = () => {
        const {reportName, allowEditReportName} = this.state;
        return <div className="wrapper1" onClick={(e) => e.stopPropagation()}>
                <Input
                    value={reportName}
                    reference={this.input}
                    onChange={(text) => this.setReportName(text)}
                    editingFlag={allowEditReportName}
                    onClick={() => this.setState({allowEditReportName: true})}

                />
                {allowEditReportName &&
                <input type='submit' value='✓' onClick={()=> this.setState({allowEditReportName: false})}/>
                }
                <button
                    onClick={()=> {
                        this.setState({allowEditReportName: !allowEditReportName});
                        if(this.input !== null) {
                            this.input.current.focus();
                        }
                    }}
                    style={{visibility: allowEditReportName ? 'hidden' : 'visible'}}
                    className={`edit ${allowEditReportName ? '' : 'with-margin'}`}
                >
                    <img src={editIcon} alt=""/>
                </button>
            </div>
    }

    selectFilterType = (value) => {
        switch (value.value) {
            case ('range'): {
                this.setState({triggerRangeModal: true})
                break;
            }
            case ('adOps'): {
                this.setState({triggerAdOpsModal: true})
                break;
            }
            default: return;
        }
    }
    getRange = async (range) => {
        await this.setState(
            {
                triggerRangeModal: false,
                filters: {...this.state.filters, range: {
                    value: {
                        from: range.startDate,
                        to: range.endDate,
                        type: range.type,
                    },
                }
            }});
    }
    closeModal = () => {
        this.setState({triggerRangeModal: false});
    }
    selectChartType = (option) => {
        this.setState({selectedChart: option.value});
    }
    getPropertiesList = (properties) => {
        if(properties !== null ) {
            if(Object.keys(properties).length) {
                return properties.chartTypes.split(', ').map(item => {
                    return {
                        value: item,
                        label: Constants.chartTypes[item]
                    }
                })
            } else return []
        } else return []
    }
    generateScheduledReport = async () => {
        const {filters,reportName, selectedChart} = this.state;
        const {data,networkId, navigate, logout} = this.props;

        this.setState({isGenerating: true})
        let payload = {
            "templateId": data.id,
            "userId":CookieService.getCookie('uuid'),
            "networkId": networkId,
            "dateRangeType": filters.range.value.type,
            "reportName": reportName,
            "defaultChart": selectedChart,
            "startDate": {
                "year": moment(filters.range.value.from).year(),
                "month": moment(filters.range.value.from).month() + 1,
                "day": moment(filters.range.value.from).date(),
            },
            "endDate": {
                "year": moment(filters.range.value.to).year(),
                "month": moment(filters.range.value.to).month() + 1,
                "day": moment(filters.range.value.to).date(),
            }
        }

        let generatedReport = await new ReportService().generateReport(payload);
        if(generatedReport.status !== 500) {
            let dataById = await new ReportService().getReportInfoById(generatedReport.id);
            // report data was received
            navigate(`/reports/${dataById.id}`);

        } else {
            logout();
        }
        this.setState({isGenerating: false});
    }
    render() {
        const {data, triggerRangeModal, filters,triggerMessageModal, selectedChart, contentWidth} = this.state;
        let disableButtons = !(filters.range.value.from && selectedChart.length);
        let from = filters.range.value.from;
        let to = filters.range.value.to;

        let range = from ? {
            startDate: [
                moment(from).year(),
                moment(from).month() + 1,
                moment(from).date(),
                moment(from).hour(),
                moment(from).minute(),
            ],
            endDate: [
                moment(to).year(),
                moment(to).month() + 1,
                moment(to).date(),
                moment(to).hour(),
                moment(to).minute(),
            ]
        } : {
            startDate: null,
            endDate: null,
        }
        return  <div ref={this.contentRef} className={`template ${contentWidth < 1200 ? 'small': ''}`} onClick={() => this.setState({allowEditReportName: false})}>
            <div className="template-left">
                <div className='template-left-chart'>
                    <h2>
                        <div className="name">
                            {data.name}
                            {this.renderRenameInput()}
                        </div>
                        <DashboardButton/>
                    </h2>
                    <div className='template-left-chart-container'>
                        <h4>{Utils.translatedString('messages.waitingGeneration')}</h4>
                    </div>
                    <div className='template-left-chart-selects'>
                        <div className='select-wrapper select'>
                            <Dropdown
                                options={[{value:'range', label:'Date Range'}]}
                                onChange={(e)=>this.selectFilterType(e)}
                                placeholder="Date Filter"
                            />
                            <Dropdown
                                options={this.getPropertiesList(data.properties)}
                                onChange={(option)=>this.selectChartType(option)}
                                placeholder="Select Chart Type"
                            />
                        </div>
                    </div>
                </div>
                <div className="template-left-controls">
                    <GenerateButton
                        onClick={() => this.setState({triggerMessageModal: true})}
                        disabled={disableButtons}
                        load={false}
                    />
                    <div className={`select-wrapper custom-dropdown disabled-link`}>
                        <img src={''} alt=""/>
                        <span>Download</span>
                        <div className="custom-dropdown-list">
                            <DownloadButton disabled={false} uuid={''} reportId={''}/>
                            <SourceButton disabled={false} reportId={''}/>
                        </div>
                    </div>
                    <RemoveButton onClick={() => {}} disabled={true}/>
                </div>
            </div>
            <div className='template-right'>
                <div className="template-right-description">
                    <div className="template-right-description-info">
                        <h2>{Utils.translatedString('properties.title')}</h2>
                        <ReportProperties data={
                            {
                                ...data,
                                ...{dateRange: range},
                                ...{dateRangeType: filters.range.value.type}
                            }}/>
                    </div>
                </div>
                <div className="template-right-controls">
                </div>
            </div>
            {triggerRangeModal &&
            <DateRange
                getRange={this.getRange}
                onCancel={this.closeModal}
                type={filters.range.value.type}
                data={data}/>}

            {triggerMessageModal && <ModalWindow
                content={<h3>{Utils.translatedString('messages.reportWillBeTomorrow')}</h3>}
                onApply={() => {
                    this.setState({triggerMessageModal: false})
                    this.generateScheduledReport();
                }}
                onCancel={null}
            />}

        </div>
    }

}
