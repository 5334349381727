import CookieService from "../services/cookieService";

export default class Request{
    url;
    uuid;

    constructor(url = ''){
        this.url = url;
        this.uuid = CookieService.getCookie('uuid');

    }

    async fetch(){
        try {
            return await fetch(this.url, {
                headers: {
                    'Application': 'reports',
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                if(result.status === 200) {
                    return result.json();
                } else {
                    return null;
                }
            });
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async http(){
        try {
            return await fetch(this.url, {
                headers: {
                    'Application': 'reports',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                }
            })
                .then((result) => result.text());
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async check(){
        try {
            return await this.http()
                .then((result) => JSON.parse(result as string));
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    async generate(payload: Object){
        try {
            return await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Application': 'reports',
                    'Version': '0.1',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            })
                .then((result) => result.json());
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async send(file: any){
        try {
            return await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Application': 'reports',
                    'Version': '0.1',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                },
                body: file,
            })
                .then((result) => result);
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    async rename(reportId: string, newName: string){
        try {
            return await fetch(this.url + `?uuid=${this.uuid}&reportId=${reportId}&newName=${newName}`, {
                method: 'GET',
                headers: {
                    'Application': 'reports',
                    'Version': '0.1',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                },
            })
                .then((result) => result);
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    async delete(id: string){
        try {
            return await fetch(this.url + `?id=${id}&uuid=${this.uuid}`, {
                method: 'DELETE',
                headers: {
                    'Application': 'reports',
                    'Version': '0.1',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                },
            })
                .then((result) => result);
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    async update(payload: any) {
        try {
            return await fetch(this.url, {
                method: 'PUT',
                headers: {
                    'Application': 'reports',
                    'Version': '0.1',
                    'Access-Control-Allow-Origin': 'https://test.apps.wiredcarbon.com/',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            })
                .then(result => result);
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}
