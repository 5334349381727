import React,{useEffect, useState} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ReportService from "../../../lib/services/reportService";
import {Loader} from "../../common/UI";
import Layout from './layout';
import './styles.scss';

export default function Template({currentId, logout, menuState}) {
    const navigate = useNavigate();
    const urlParams = useParams();
    const location = useLocation();
    const [template, setTemplate] = useState(null);

    useEffect(async ()=> {
        if(template === null) {
            const categories = await new ReportService().getReportsList(currentId);
            setTemplate(categories.find(obj => obj.id === urlParams.id))
        } else {
            setTemplate(location.state);
        }
    },[location])

    if(template === undefined) {
        return <div className='wrapper-center'><h4>Template wasn't found</h4></div>
    } else if(template && template.id) {
        return <Layout
                data={template}
                navigate={navigate}
                networkId={currentId}
                logout={logout}
                menuState={menuState}
        />
    } else {
        return <div className='wrapper-center'><Loader size={32}/></div>
    }
};
