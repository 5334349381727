import ServerConfig from "./serverConfig";

const URL = ServerConfig.getUrl();
const IS_AUTHENTICATED_URL = URL + "api/authenticated";
const AUTH_URL = URL + "api/oauth";
const IS_ADMIN_URL = URL + "api/isAdmin";
const TEMPLATES_URL = URL + "api/templates";
const GENERATE_REPORT_URL = URL + "api/generateReport";
const UPLOAD_TEMPLATE_URL = URL + "api/uploadTemplate";
const CATEGORIES_URL = URL + "api/categoriesTemplates";
const REPORT_URL = URL + "api/reports";
const FILTERED_REPORT_BY_DATE_URL = URL + "api/filterByDateRange";
const AVAILABLE_NETWORK_IDS_URL = URL + "api/getAvailableNetworks";
const USER_NAME_URL = URL + 'api/getUserName';
const USERS_LIST_URL = URL + 'api/getUsersFromNetwork';
const ACCESS_EXTENSION_URL = URL + 'api/checkExtentionAccess';
const ACCESS_GAM_URL = URL + 'api/checkAdManagerAccess';
const IMPERSONATE_URL = URL + 'api/impersonate';
const UNIMPERSONATE_URL = URL + 'api/unImpersonate';
const IS_IMPERSONATED_URL = URL + 'api/isImpersonated';
const DOWNLOAD_REPORT_URL = URL + 'api/downloadReport';
const DOWNLOAD_RAW_REPORT_DATA_URL = URL + 'api/downloadRawReportData';
const DOWNLOAD_PIVOT_TABLE_URL = URL + 'api/downloadPivotTable';
const RENAME_REPORT_URL = URL + 'api/renameReport';
const SUPPORT_URL = 'https://www.wiredcarbon.com/contact/';

export {
    URL,
    IS_AUTHENTICATED_URL,
    SUPPORT_URL,
    AUTH_URL,
    IS_ADMIN_URL,
    TEMPLATES_URL,
    GENERATE_REPORT_URL,
    UPLOAD_TEMPLATE_URL,
    CATEGORIES_URL,
    REPORT_URL,
    FILTERED_REPORT_BY_DATE_URL,
    AVAILABLE_NETWORK_IDS_URL,
    USER_NAME_URL,
    USERS_LIST_URL,
    ACCESS_EXTENSION_URL,
    ACCESS_GAM_URL,
    IMPERSONATE_URL,
    UNIMPERSONATE_URL,
    IS_IMPERSONATED_URL,
    DOWNLOAD_REPORT_URL,
    DOWNLOAD_RAW_REPORT_DATA_URL,
    DOWNLOAD_PIVOT_TABLE_URL,
    RENAME_REPORT_URL,
};
