import React,{useEffect, useState} from "react";
import {useParams, useNavigate, useLocation } from "react-router-dom";
import ReportService from "../../../lib/services/reportService";
import {Loader} from "../../common/UI";
import Layout from './layout';
import './../templatePage/styles.scss';

export default function Report({currentId, logout, menuState}) {
    const navigate = useNavigate();
    const urlParams = useParams();
    const location = useLocation();
    const [report, setReport] = useState(null);

    useEffect(async ()=> {
        let dataById = await new ReportService().getReportInfoById(urlParams.id);
        if(dataById === null) {
            setReport(undefined)
        } else {
            setReport(dataById);
        }
    },[location])
    if(report === undefined) {
        return <div className='wrapper-center'><h4>Report wasn't found</h4></div>
    } else if(report && report.id) {
        return <Layout data={report}
                       navigate={navigate}
                       networkId={currentId}
                       logout={logout}
                       updateReport={() =>setReport(report.id)}
                       menuState={menuState}
        />
    } else {
        return <div className='wrapper-center'><Loader size={32}/></div>
    }
};
