import React from "react";
import NetworkService from "../../../lib/services/networkServices";
import logoPath from '../../../lib/assets/logo.svg';
import logoPath2 from '../../../lib/assets/logo.png';
import Utils from "../../../lib/utils/utils";
import './styles.scss';

interface Props {
    handleLogin: () => void,
}
export default class Login extends React.Component<Props, {}>{
    handleLogin = async () => {
        await new NetworkService().login()
            .then(
                (result:string|boolean) => {
                    const left = (window.screen.width / 2) - (800 / 2);
                    const top = (window.screen.height / 2) - (600 / 2);
                    const linkFeature:string = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=800px, height=600px, top=${top}px, left=${left}px`;
                    let win:any;
                    if(result) {
                        win = window.open(result.toString(), "Authentication", linkFeature);
                        win?.focus();
                    }
                    const timer = setInterval(function() {
                        if(win?.closed) {
                            clearInterval(timer);
                            window.location.reload();
                        }
                    }, 1000);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    render(){
        return (
            <div className='login-container'>
                <div className="login-container-content">
                    <h2>
                        {Utils.translatedString('messages.needToLogin')}
                    </h2>
                    <button onClick={() => this.handleLogin()}>
                        {Utils.translatedString('controls.login')}
                    </button>
                </div>
                <img className="logo-image" src={logoPath2} alt={'logo'}/>
                <div className="logo" style={{backgroundImage: `url(${logoPath})`}}/>
            </div>
        );
    }
}
