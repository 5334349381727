import { v4 as uuidv4 } from 'uuid';

export default class CookieService {
    // Function for getting cookie
    static getCookie(cookieName: string): string {
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Function for setting cookie
    static setCookie(cookieName: string, cookieValue: any, expireDays: number): void {
        const day = new Date();
        day.setTime(day.getTime() + (expireDays*24*60*60*1000));
        let expires = "expires="+ day.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    // Function for checking and setting uuid cookie
    static setUuidCookie(): void {
        !(CookieService.getCookie("uuid") !== "") && CookieService.setCookie("uuid", uuidv4(), 60);
    }
}
