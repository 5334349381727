import React from "react";
import './style.scss';
import UserService from "../../lib/services/userService";
import Utils from "../../lib/utils/utils";

interface State {
    id: string,
    name: string,
}
interface Props {
    logout: () => void,
    getId: (id: string) => void,
    networkIds: Array<string>,
}
export default class Header extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            id: localStorage.getItem('networkId') || this.props.networkIds[0],
            name: '',
        }
    }
    componentDidMount() {
        this.props.getId(this.state.id);
        localStorage.setItem('networkId', this.state.id);
        this.getUsername();
    }
    getUsername = async () => {
        let name = await new UserService().getUsername();
        this.setState({name});
    }
    onNetworkChange = (id: string) => {
        this.props.getId(id);
        localStorage.setItem('networkId', id);
        this.setState({id}, () => document.location.href="/dashboard");
    }
    render(){
        const {networkIds} = this.props;
        const {name} = this.state;
        return(
            <header>
                <div className="header-wrapper">
                    <div className="container">
                        <div className="header-row info-container">
                            <div className="info">
                                <select name="" className="user" value={'user'} onChange={() => this.props.logout()}
                                >
                                    <option defaultValue={'user'} value={'user'} style={{display: 'none'}}>
                                        {Utils.translatedString('hello')}, {name}
                                    </option>
                                    <option value={'logout'}>{Utils.translatedString('controls.logout')}</option>
                                </select>
                                {networkIds.length === 1 ? <span id="network-id">Network ID: {networkIds[0]}</span> :
                                    <span>
                                    <span>{Utils.translatedString('networkId')}:</span>
                                    <select name="" className="network-id" value={this.state.id}
                                            onChange={(value) => this.onNetworkChange(value.currentTarget.value)}
                                    >
                                    {networkIds.map((item, index) =>
                                        <option
                                            key={item + index}
                                            value={item}>
                                            {item}
                                        </option>)
                                    }
                                </select>
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
