import React from "react";
import logoPath from '../../../lib/assets/logo.svg';
import logoPath2 from '../../../lib/assets/logo.png';
import {SUPPORT_URL} from "../../../lib/config/urls";
import Utils from "../../../lib/utils/utils";
import './styles.scss';

interface Props {
    logout: () => void,
}
export default class GamAccess extends React.Component<Props, {}>{

    render(){
        return (
            <div className='login-container'>
                <div className="login-container-content">
                    <h2>
                        {Utils.translatedString('messages.noAccessNetworkId')}
                        <a href={SUPPORT_URL} className='login-container-content-link' onClick={()=>{}}>
                            {SUPPORT_URL}
                        </a>
                    </h2>
                    <button
                        className='main-message-btn'
                        onClick={() => this.props.logout()}
                    >
                        {Utils.translatedString('controls.retry')}
                    </button>
                </div>
                <img className="logo-image" src={logoPath2} alt={'logo'}/>
                <div className="logo" style={{backgroundImage: `url(${logoPath})`}}/>
            </div>
        );
    }
}
