import {
    IS_AUTHENTICATED_URL,
    AUTH_URL,
    IS_ADMIN_URL,
    AVAILABLE_NETWORK_IDS_URL,
} from "../config/urls";
import HttpUrls from "../interfaces/httpUrls";
import Request from "../interfaces/request";

export default class NetworkService extends HttpUrls{

    // Check if user is login
    async isLoggin(){
        const URL = IS_AUTHENTICATED_URL + this.uuid;

        return await new Request(URL).check();
    }
    // Get url from login for parsing
    async login(){
        const URL = AUTH_URL + this.uuid;
        return await new Request(URL).http();
    }
    // Get url from login for parsing
    async isAdmin (){
        const URL = IS_ADMIN_URL + this.uuid;
        return await new Request(URL).check();
    }
    // Get url from login for parsing
    async availableNetworkIds (){
        const URL = AVAILABLE_NETWORK_IDS_URL + this.uuid;
        return await new Request(URL).fetch();
    }
}
