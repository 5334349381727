import React from "react";
import Utils from "../../../lib/utils/utils";
import {AiOutlineRight} from "react-icons/ai";


export default function DashboardButton() {
    return (
        <div className='back-wrapper'>
            <a
                className={`back with-icon`}
                href={'/dashboard'}
            >
                <span>
                    {Utils.translatedString('controls.backToDashboard')}
                </span>
                <div className="btn-icon">
                    <AiOutlineRight/>
                </div>
            </a>
        </div>
    );
}
