import CookieService from "../services/cookieService";

export default class HttpUrls{
    uuid;
    networkId;

    constructor(){
        this.uuid = "?uuid=" + CookieService.getCookie('uuid');
        this.networkId = "networkId=" + localStorage.getItem('networkId');
    }
}
