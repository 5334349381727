import React from 'react';
import './styles.scss';
import {Constants} from "../../../lib/config/constants";
import {Link} from "react-router-dom";

interface Props {
    list: Array<any>,
}
export default function TemplatesList({list}:Props) {
    return <div className='templates-list'>
        <h4>Templates Repository</h4>
        {Constants.CATEGORIES.map((item, index) =>
        <details key={item + index}>
            <summary>{item}</summary>
            {list.map((element, key) => (
                <div key={element.id + key} >
                    {element.category === item && <div className='categories-list'>
                        <Link to={`/templates/${element.id}`} state={element}>
                            {element.name || element.instructions.SELECT.Report_name}
                        </Link>
                    </div>}
                </div>
            ))}
        </details>)}</div>
}

