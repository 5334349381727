import React from "react";
import ReportService from "../../../lib/services/reportService";
import ModalWindow from "../../common/modal";
import {Loader} from "../../common/UI";
import ReportsList from './reportsList';
import Utils from "../../../lib/utils/utils";
import {Constants} from "../../../lib/config/constants";
import nextIcon from './../../../lib/assets/next.svg';
import prevIcon from './../../../lib/assets/prev.svg';
import './styles.scss';
interface Props {
    state: any,
    currentNetwork: string,
}
interface State {
    expanded: boolean,
    loaded: boolean,
    savedReports: any,
    askDeleteReport: boolean,
    reportIdToDelete: string,
    reportsPerPage: number,
    currentPage: number,
    totalPagesCount: number,
    totalElements: number,

}
export default class Dashboard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: this.props.state,
            loaded: false,
            savedReports: [],
            askDeleteReport: false,
            reportIdToDelete: '',
            reportsPerPage: parseInt(localStorage.getItem('reportsPerPage') ||  Constants.reportsPerPage[0].toString()) || Constants.reportsPerPage[0],
            currentPage: parseInt(localStorage.getItem('currentPage') || '0') || 0,
            totalElements: 0,
            totalPagesCount: 1,
        }
    }
    async componentDidMount() {
        await this.getSavedReports();
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            expanded: nextProps.state,
        });
    }
    getSavedReports = async () => {
        const {reportsPerPage, currentPage} = this.state;
        let data = await new ReportService().getAllReports(this.props.currentNetwork, reportsPerPage, currentPage);
        console.log('dashboard: ', this.props.currentNetwork, reportsPerPage, currentPage);
        this.setState({
            savedReports: data.content,
            loaded: true,
            totalElements: data.totalElements,
            totalPagesCount: Math.ceil(data.totalElements / this.state.reportsPerPage)
        });
    }

    deleteReport = () => {
        new ReportService().deleteReport(this.state.reportIdToDelete)
            .then((res: any) => {
                res.ok && this.getSavedReports()
            });
    }
    allowReportDelete = (id: string) => {
        this.setState({
            askDeleteReport: true,
            reportIdToDelete: id,
        });
    }

    changeReportsCountPerPage = (value:number) => {
        this.setState({
            reportsPerPage: value,
            currentPage: 0,
            loaded: false,
            savedReports: [],

        }, ()=> this.getSavedReports());
        localStorage.setItem('currentPage', '0');
        localStorage.setItem('reportsPerPage', value.toString());
    }
    renderPagination = () => {
        const {totalPagesCount, currentPage} = this.state;
        let numbers = [...Array(totalPagesCount).keys()];
        return <div className='pagination'>
            <div className="pagination-pages">
                <button
                    disabled={!(currentPage > 0)}
                    onClick={()=> currentPage > 0 && this.changePage(currentPage - 1)}
                >
                    <img src={prevIcon} alt=""/>
                </button>
                {numbers.map(item =>
                    <button
                        key={item}
                        className={`${item === currentPage ? 'active': ''}`}
                        onClick={()=> this.changePage(item)}
                    >
                        {item +1}
                    </button>
                )}

                <button
                    disabled={!(currentPage + 1 < totalPagesCount)}
                    onClick={()=> (currentPage + 1) < totalPagesCount &&  this.changePage(currentPage + 1)}
                >
                    <img src={nextIcon} alt=""/>
                </button>
            </div>
            <div className="pagination-select">
                <select value={this.state.reportsPerPage} onChange={(event) =>
                    this.changeReportsCountPerPage(parseInt(event.currentTarget.value))}>
                    {Constants.reportsPerPage.map((item, index) =>
                        <option
                            key={item+index}
                            value={item}

                        >
                            {item} Reports on page
                        </option>
                    )}
                </select>
            </div>

        </div>
    }

    changePage = (page: number) => {
        localStorage.setItem('currentPage', page.toString());
        this.setState({
            currentPage: page,
            savedReports: [],
            loaded: false,
        }, () => this.getSavedReports());
    }
    render() {
        const {loaded, askDeleteReport, savedReports} = this.state;
        return (
            <>
                {askDeleteReport && <ModalWindow
                    content={
                        <h3>
                            {Utils.translatedString('messages.sureToDelete')}
                        </h3>
                    }
                    onApply={()=> {
                        this.deleteReport();
                        this.setState({
                            askDeleteReport: false,
                            loaded: false,
                            reportIdToDelete: '',
                            savedReports: [],
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            askDeleteReport: false,
                            reportIdToDelete: '',
                        })}
                    }
                    applyTitle={Utils.translatedString('controls.remove')}
                    cancelTitle={Utils.translatedString('controls.cancel')}
                />}

                <div className={`expandedContainer ${savedReports.length === 0 ? 'empty': ''}`}>
                    {loaded ? <ReportsList
                        reports={savedReports}
                        allowReportDelete={this.allowReportDelete}
                    /> : <Loader size={32}/> }
                </div>
                {savedReports.length > 0 && this.renderPagination()}
            </>
        );
    }
};
