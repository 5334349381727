import HttpUrls from "../interfaces/httpUrls";
import Request from "../interfaces/request";
import {
    TEMPLATES_URL,
    REPORT_URL, GENERATE_REPORT_URL, RENAME_REPORT_URL,
} from "../config/urls";
import CookieService from "./cookieService";

export default class ReportService extends HttpUrls{

    async getReportInfoById(id: number){
        const uuid = CookieService.getCookie('uuid');
        const URL = `${REPORT_URL}?id=${id}&uuid=${uuid}`;
        return await new Request(URL).fetch()
            .then(result => result);
    }
    async generateReport(payload: Object){
        return await new Request(GENERATE_REPORT_URL).generate(payload)
            .then(
                (response) => {
                    return response;
                }
            );
    }

    async getAllReports(networkId: string,reportsPerPage: number, currentPage:number){
        return await new Request(REPORT_URL + this.uuid + `&networkId=${networkId}&page=${currentPage}&elementsPerPage=${reportsPerPage}`).fetch()
            .then(
                (list) => {
                    return list;
                }
            );
    }

    async getReportsList(networkId: string){
        const uuid = CookieService.getCookie('uuid');
        return await new Request(TEMPLATES_URL + `?networkId=${networkId}&uuid=${uuid}`).fetch()
            .then(
                (list) => {
                    return list
                }
            )
            .catch(e => {
                throw(e)
            })
    }


    async changeReportName(id: string, newName: string) {
        return await new Request(RENAME_REPORT_URL).rename(id, newName)
            .then(
                (result) => result
            );
    }
    async deleteReport(id: string) {
        return await new Request(REPORT_URL).delete(id)
            .then(
                (result) => result
            );
    }
    async updateExistingReport(data: any) {
        return await new Request(REPORT_URL).update(data)
            .then(
                (response) => {
                    return response;
                }
            );
    }
}
