import moment from "moment";
import {Constants} from "../../lib/config/constants";
import {AiOutlineInfoCircle} from 'react-icons/ai';
export default function ReportProperties(props: any) {
    let data = props.data;
    let generationDate = data.generatedDate ? new Date(
        data.generatedDate[0],
        data.generatedDate[1] - 1,
        data.generatedDate[2],
        data.generatedDate[3],
        data.generatedDate[4],
    ) : null;
    let dateRangeStart = data.dateRange?.startDate ? new Date(
        data.dateRange.startDate[0],
        data.dateRange.startDate[1] - 1,
        data.dateRange.startDate[2],
        data.dateRange.startDate[3],
        data.dateRange.startDate[4],
    ) : null;
    let dateRangeEnd = data.dateRange?.endDate ? new Date(
        data.dateRange.endDate[0],
        data.dateRange.endDate[1] - 1,
        data.dateRange.endDate[2],
        data.dateRange.endDate[3],
        data.dateRange.endDate[4],
    ) : null;
    return <>
        <div className="template-right-description-info-text">
            <div>Report job ID:</div>
            <div>{data.id || '--'}</div>
        </div>
        <div className="template-right-description-info-text">
            <div>Date/Time generated:</div>
            {generationDate ?
                <div>{moment(generationDate).format('YYYY-MM-DD HH:MM A')}</div> :
                <div>--</div>
            }
        </div>
        <div className="template-right-description-info-text">
            <div>Publisher network name:</div>
            <div>{data?.networkName || '--'}</div>
        </div>
        <div className="template-right-description-info-text">
            <div>User:</div>
            <div>{data?.userEmailAddress || '--'}</div>
        </div>
        <div className="template-right-description-info-text">
            <div>Category:</div>
            <div>{data.template?.category || data.category || '--'}</div>
        </div>
        <div className="template-right-description-info-text">
            <div>Date Range: {props.type === 'report' ? <AiOutlineInfoCircle/> : null}</div>
            <div>
                <div>{Constants.RANGES[data.dateRangeType || 'CUSTOM']}</div>
                {(dateRangeStart) ? moment(dateRangeStart).format('YYYY/MM/DD') : ''}
                {' -- '}

                {(dateRangeEnd) ? moment(dateRangeEnd).format('YYYY/MM/DD') : ''}
            </div>
        </div>
        <div className="template-right-description-info-text">
            <div>Name:</div>
            <div>{data?.template?.instructions.SELECT.Report_name || data.name || '--'}</div>
        </div>
    </>
}
