import React from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment";

import ModalWindow from "../../common/modal";
import Utils from "../../../lib/utils/utils";
import DashboardButton from "../../templateAndReport/buttons/goBack";
import DownloadButton from "../../templateAndReport/buttons/downoad";
import SourceButton from "../../templateAndReport/buttons/viewSource";
import CookieService from "../../../lib/services/cookieService";
import RemoveButton from "../../templateAndReport/buttons/remove";
import ReportProperties from "../../templateAndReport/reportProperties";
import GenerateButton from "../../templateAndReport/buttons/generate";
import {Constants} from "../../../lib/config/constants";
import DateRange from "../../templateAndReport/dateRange/range";
import {FILTERED_REPORT_BY_DATE_URL} from "../../../lib/config/urls";
import ReportService from "../../../lib/services/reportService";
import icon from './../../../lib/assets/download.svg';
import {Loader} from "../../common/UI";
import DownloadTableButton from "../../templateAndReport/buttons/downloadPivotTable";
import {AiOutlineInfoCircle} from 'react-icons/ai';

export default class Layout extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reportName: props.data.reportName,
            allowEditReportName: false,
            triggerMessageOvernight: false,
            filters: {
                range: {
                    value: {from: null,to: null, type: ''},
                },
                adOps: {value: []}
            },
            triggerRangeModal: false,
            triggerAdOpsModal: false,
            triggerCreateOrEditModal: false,
            triggerCreateModalAfterConfirm: false,
            triggerEditModalAfterConfirm: false,
            triggerErrorModal: false,
            isFilteredDataLoading: false,
            properties: [],
            selectedChart: props.data.defaultChart || '',
            isGenerating: false,
            data: props.data,
            initialRange: {
                from: null,
                to: null,
            },
            initialRangeType: props.data.defaultChart,
            contentWidth: 0,
            localState: true,
        }
        this.input = React.createRef();
        this.contentRef = React.createRef();
    }
    componentDidMount() {
        this.setState({contentWidth: this.contentRef.current.getBoundingClientRect().width});
        window.addEventListener('resize', this.checkWindowWidth);
        this.setState({
            filters: {
                range: {
                    value: {
                        from:
                            new Date(
                                this.props.data.dateRange.startDate[0],
                                this.props.data.dateRange.startDate[1]-1,
                                this.props.data.dateRange.startDate[2],
                                this.props.data.dateRange.startDate[3],
                                this.props.data.dateRange.startDate[4],
                            ),
                        to: new Date(
                                this.props.data.dateRange.endDate[0],
                                this.props.data.dateRange.endDate[1]-1,
                                this.props.data.dateRange.endDate[2],
                                this.props.data.dateRange.endDate[3],
                                this.props.data.dateRange.endDate[4],
                            ),
                        type: this.props.data.dateRangeType,
                    },
                },
                adOps: {value: []}
            },
            initialRange: {
                from:
                    new Date(
                        this.props.data.dateRange.startDate[0],
                        this.props.data.dateRange.startDate[1]-1,
                        this.props.data.dateRange.startDate[2],
                        this.props.data.dateRange.startDate[3],
                        this.props.data.dateRange.startDate[4],
                    ),
                to: new Date(
                    this.props.data.dateRange.endDate[0],
                    this.props.data.dateRange.endDate[1]-1,
                    this.props.data.dateRange.endDate[2],
                    this.props.data.dateRange.endDate[3],
                    this.props.data.dateRange.endDate[4],
                )
            },
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // reset initial date ranges after generation report
        this.setState({
            localState: nextProps.menuState,
            initialRange: {
                from: this.state.filters.range.value.from,
                to: this.state.filters.range.value.to,
            },
        }, () => this.checkWindowWidth());

    }
    componentDidUpdate = () => {
        if (this.hasChanges()) {
            window.onbeforeunload = function(evt) {
                let message = 'Are you sure you want to leave the page? All data will be lost!';
                if (typeof evt === 'undefined') {
                    evt = window.event;
                }
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            };
        } else {
            window.onbeforeunload = undefined
        }
    }
    checkWindowWidth = () => {
        const {localState} = this.state;
            this.setState({contentWidth: localState ? window.innerWidth - 80 : window.innerWidth - 240});
    }
    setReportName = (text) => {
        this.setState({reportName: text});
    }

    selectFilterType = (value) => {
        switch (value.value) {
            case ('range'): {
                this.setState({triggerRangeModal: true})
                break;
            }
            case ('adOps'): {
                this.setState({triggerAdOpsModal: true})
                break;
            }
            default: return;
        }
    }
    removeReport = async () => {
        await new ReportService().deleteReport(this.state.data.id)
            .then(() => this.props.navigate('/dashboard'));
    }
    getRange = async (range) => {
        await this.setState(
            {
                triggerRangeModal: false,
                filters: {...this.state.filters, range: {value:
                            {
                                from: range.startDate,
                                to: range.endDate,
                                type: range.type,
                            },
                    }
                }});
        if(this.state.data.status === 'READY') {
            this.setState({
                isFilteredDataLoading: true,
            });
            const fetchData = async () => {
                const payload = {
                    reportId: this.state.data.id,
                    startDate: moment(range.startDate).format('YYYY-MM-DD'),
                    endDate: moment(range.endDate).format('YYYY-MM-DD'),
                    userId: CookieService.getCookie('uuid'),
                };
                axios.post(FILTERED_REPORT_BY_DATE_URL, payload)
                    .then((response) => response)
                    .then((r) => {
                        let oldData = this.state.data;
                        oldData.data = r.data;
                        this.setState({
                            data: {...oldData},
                            isFilteredDataLoading: false,
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        this.setState({
                            isFilteredDataLoading: false,
                        });
                    })
            }
            fetchData()
                .catch(console.error);
        }
    }
    closeModal = () => {
        this.setState({triggerRangeModal: false});
    }
    selectChartType = (option) => {
        if(this.hasChanges()) {
            this.setState({
                selectedChart: option.value,
            });
        } else {
            this.setState({
                selectedChart: option.value,
                isFilteredDataLoading: true,
            }, () => this.updateExistingReport().then(() => this.setState({isFilteredDataLoading: false})));
        }
    }

    getPropertiesList = (properties) => {
        if(properties !== null ) {
            if(properties.length) {
                return properties.map(item => {
                    return {
                        value: item,
                        label: Constants.chartTypes[item]
                    }
                })
            } else return [{
                value: this.state.defaultChart,
                label:  Constants.chartTypes[this.state.selectedChart]
            }]
        } else return [{
            value: this.state.defaultChart,
            label: Constants.chartTypes[this.state.selectedChart]
        }]
    }
    generateScheduledReport = async () => {
        const {filters,reportName,selectedChart, data} = this.state;
        const {networkId, navigate, logout} = this.props;

        this.setState({isGenerating: true})
        let payload = {
            "templateId": data.template.id,
            "userId":CookieService.getCookie('uuid'),
            "networkId": networkId,
            "dateRangeType": filters.range.value.type,
            "reportName": reportName,
            "defaultChart": selectedChart || 'LINE',
            "startDate": {
                "year": moment(filters.range.value.from).year(),
                "month": moment(filters.range.value.from).month() + 1,
                "day": moment(filters.range.value.from).date(),
            },
            "endDate": {
                "year": moment(filters.range.value.to).year(),
                "month": moment(filters.range.value.to).month() + 1,
                "day": moment(filters.range.value.to).date(),
            }
        }

        let generatedReport = await new ReportService().generateReport(payload);
        if(generatedReport.status !== 500) {
            let dataById = await new ReportService().getReportInfoById(generatedReport.id);
            // report data was received
            navigate(`/reports/${dataById.id}`);

        } else {
            logout();
        }
        this.setState({isGenerating: false});
    }

    updateExistingReport = async () => {
        const {data, selectedChart, filters} = this.state;
        this.setState({
            triggerCreateOrEditModal: false,
        });
        const payload = {
            id: data.id,
            reportName: data.reportName,
            dateRange: {
                startDate: moment(filters.range.value.from).utc(true).toISOString(),
                endDate: moment(filters.range.value.to).utc(true).toISOString(),
            },
            dateRangeType: filters.range.value.type,
            defaultChart: selectedChart,
            userId: CookieService.getCookie('uuid'),
        }
        let updateResult = await new ReportService().updateExistingReport(payload);
        if(updateResult.status === 200) {
            this.setState({
                initialRange: {
                    from: filters.range.value.from,
                    to: filters.range.value.to,
                },
                initialRangeType: selectedChart,
            });
        } else {
            this.setState({
                triggerErrorModal: true,
            });
        }
    }
    hasChanges = () => {
        const {filters, initialRange} = this.state;
        let fromSelected = moment(filters.range.value.from).format('YYYY/MM/DD');
        let toSelected = moment(filters.range.value.to).format('YYYY/MM/DD');
        let fromInitial = moment(initialRange.from).format('YYYY/MM/DD');
        let toInitial = moment(initialRange.to).format('YYYY/MM/DD');
        return !(
            moment(fromSelected).isSame(fromInitial) &&
            moment(toSelected).isSame(toInitial)
        );
    }
    parseHTML = () => {
        const {data, selectedChart} = this.state;
        if(selectedChart === 'NO_CHART') {
            let html = parse(data.data.charts[selectedChart || 'LINE']);
            let table = React.Fragment;
            if(html) {
                html.props.children.forEach(htmlChild => {
                    if(htmlChild.type === 'body') {
                        htmlChild.props.children.forEach(bodyChild => {
                            if(bodyChild.type === 'table') {
                                table = bodyChild;
                            }
                        })
                    }
                })
            }
            return <div className='aaa'>{table}</div>;
        } else {
            return parse(data.data.charts[selectedChart || 'LINE'])
        }
    }
    render() {
        const {data, triggerRangeModal,
            filters,selectedChart,
            triggerCreateModalAfterConfirm,
            triggerEditModalAfterConfirm,
            triggerCreateOrEditModal,
            triggerDeleteModal,
            triggerErrorModal,
            isFilteredDataLoading,
            contentWidth,
        } = this.state;
        let from = filters.range.value.from;
        let to = filters.range.value.to;

        let range = from ? {
            startDate: [
                moment(from).year(),
                moment(from).month() + 1,
                moment(from).date(),
                moment(from).hour(),
                moment(from).minute(),
            ],
            endDate: [
                moment(to).year(),
                moment(to).month() + 1,
                moment(to).date(),
                moment(to).hour(),
                moment(to).minute(),
            ]
        } : {
            startDate: null,
            endDate: null,
        }
        return <div
            className={`template ${contentWidth < 1200 ? 'small': ''}`}
            ref={this.contentRef}
            onClick={() => this.setState({allowEditReportName: false})}>
            <div className="template-left">
                <div className='template-left-chart'>
                    <h2>
                        <div className="name">
                            {data.template.name} {data.reportName}
                        </div>
                        <DashboardButton/>
                    </h2>
                    <div className='template-left-chart-container'>
                        {data.status === 'PENDING' ?
                            <h4>{Utils.translatedString('messages.overnightGeneration')}</h4> :
                            <div className={`svg-wrapper ${isFilteredDataLoading ? 'blur': ''} ${selectedChart === 'NO_CHART' ? 'no-chart' : ''}`}>
                                {this.parseHTML()}
                            </div>
                        }
                        {isFilteredDataLoading ? <div className='loader-wrapper'>
                            <Loader size={48}/>
                        </div> : null}

                    </div>
                    <div className='template-left-chart-selects'>
                        <div className='select-wrapper select'>
                            <Dropdown
                                options={[{value:'range', label:'Date Range'}]}
                                onChange={(e)=>this.selectFilterType(e)}
                                placeholder="Date Filter"
                            />
                            <Dropdown
                                options={this.getPropertiesList(data.template.properties.chartTypes.split(', '))}
                                value={selectedChart}
                                onChange={(option)=>this.selectChartType(option)}
                                placeholder="Select Chart Type"
                            />
                        </div>
                    </div>
                </div>
                <div className="template-left-controls">
                    <GenerateButton
                        onClick={() => this.setState({triggerCreateOrEditModal: true})}
                        disabled={!this.hasChanges()}
                        load={false}
                    />
                    <div className={`select-wrapper custom-dropdown ${data.status === 'PENDING' ? 'disabled-link' : ''}`}>
                        <img src={icon} alt=""/>
                        <span>Download</span>
                        <div className="custom-dropdown-list">
                            <DownloadButton
                                disabled={false}
                                uuid={CookieService.getCookie("uuid")}
                                reportId={data.id}
                            />
                            <SourceButton
                                disabled={false}
                                uuid={CookieService.getCookie("uuid")}
                                reportId={data.id}
                            />
                            <DownloadTableButton
                                disabled={false}
                                uuid={CookieService.getCookie("uuid")}
                                reportId={data.id}
                            />
                        </div>
                    </div>
                    <RemoveButton onClick={() => this.setState({triggerDeleteModal: true})} disabled={false}/>
                </div>
            </div>
            <div className='template-right'>
                <div className="template-right-description">
                    <div className="template-right-description-info">
                        <h2>{Utils.translatedString('properties.title')}</h2>
                        <ReportProperties data={
                            {
                                ...data,
                                ...{dateRange: range},
                                ...{dateRangeType: filters.range.value.type}
                            }}
                                          type={'report'}
                        />
                    </div>
                    <span className='note'>
                        <b><AiOutlineInfoCircle/> </b>
                        {Utils.translatedString('messages.note')}
                    </span>
                </div>
            </div>
            {triggerRangeModal &&
            <DateRange
                getRange={this.getRange}
                onCancel={this.closeModal}
                type={filters.range.value.type}
                data={data}/>}

            {triggerCreateOrEditModal && <ModalWindow
                content={<h3>{Utils.translatedString('messages.editOrCreateReport')}</h3>}
                onApply={()=> this.updateExistingReport().then(() => this.setState({triggerEditModalAfterConfirm: true}))}
                onCancel={()=> {
                    this.setState({
                        triggerCreateModalAfterConfirm: true,
                        triggerCreateOrEditModal: false,
                    })
                }}
                onClose={()=> {
                    this.setState({
                        triggerCreateModalAfterConfirm: false,
                        triggerCreateOrEditModal: false,
                    })
                }}
                close={true}
                applyTitle={Utils.translatedString('controls.edit')}
                cancelTitle={Utils.translatedString('controls.createNew')}
            />}
            {triggerCreateModalAfterConfirm && <ModalWindow
                content={<h3>{Utils.translatedString('messages.reportWillBeTomorrow')}</h3>}
                onApply={() => {
                    this.setState({triggerCreateModalAfterConfirm : false})
                    this.generateScheduledReport();
                }}
                onCancel={null}
            />}
            {triggerEditModalAfterConfirm && <ModalWindow
                content={<h3>{Utils.translatedString('messages.editedReportWillBeTomorrow')}</h3>}
                onApply={() => this.setState({triggerEditModalAfterConfirm : false})}
                onCancel={null}
            />}
            {triggerDeleteModal  &&
            <ModalWindow
                content={<h3>{Utils.translatedString('messages.sureToDelete')}</h3>}
                onApply={()=> {
                    this.removeReport();
                    this.setState({triggerDeleteModal: false})
                }}
                onCancel={() => {
                    this.setState({triggerDeleteModal: false})
                }}
                applyTitle={Utils.translatedString('controls.remove')}
                cancelTitle={Utils.translatedString('controls.cancel')}
            />
            }
            {triggerErrorModal && <ModalWindow
                content={<h3>Something went wrong, please try again later</h3>}
                onApply={() => this.setState({triggerErrorModal : false})}
                onCancel={null}
            />}
        </div>
    }
}
