import i18n from "i18next";

export default class Utils {
    /**
     * param {string} key - key from src/lib/lang/i18n.tsx
     */
    static translatedString(key: string, uppercase = false) { // key from lib/lang/i18n.tsx

        return uppercase ? i18n.t(key).toUpperCase() : i18n.t(key);
    }
    static sort = (list: Array<any> = [], key = '') => list.sort((a,b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) ? 1 : ((b[key].toString().toLowerCase() > a[key].toString().toLowerCase()) ? -1 : 0));

}
