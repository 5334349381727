import React, {createRef} from 'react';
import {Link} from 'react-router-dom';
import {GrFormNext, GrFormPrevious } from 'react-icons/gr';
import UploadButton from "./uploadButton";
import ReportService from "../../lib/services/reportService";
import {Loader} from "../common/UI";
import UserService from "../../lib/services/userService";
import TemplatesList from "./templatesList";

import dashboardIcon from "../../lib/assets/dashboard.svg";
import logoPath from "../../lib/assets/logo.png";
import switchIcon from "../../lib/assets/switch.svg";
import templatesIcon from "../../lib/assets/templates.svg";
import menuIcon from "../../lib/assets/menu.svg";
import '../App.scss';
import './styles.scss';

interface Props {
    isAdmin: boolean,
    onApply: any,
    currentNetwork: string,
    users: Array<any>,
}

interface State {
    open: boolean;
    superUser: boolean,
    loaded: boolean,
    templatesList: Array<{
        id: string,
        category: string,
        instructions: {
            SELECT: {
                Column_name: string,
                Report_name: string,
            },
        }
    }>,
    users:Array<any>,
    isImpersonate: boolean,
}

export default class Menu extends React.Component<Props, State> {
    private readonly uploadButton = createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props);
        this.uploadButton = React.createRef();
        this.state = {
            open: localStorage.getItem('close') !== 'false',
            superUser: true,
            templatesList: [],
            loaded: false,
            users: [],
            isImpersonate: false,
        }
    }
    componentDidMount() {
        this.getTemplatesCategory().then(() => {});
        this.getUsersList();
        this.checkImpersonate();
    }
    getUsersList = async () => {
        const users = this.props.users;
        this.setState({users});
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.users !== prevProps.users) {
            this.setState({users: this.props.users});
        }
    }

    getTemplatesCategory = async () => {
        const categories = await new ReportService().getReportsList(this.props.currentNetwork);
        this.setState({
            templatesList: categories,
            loaded:true,
        });
    }
    checkImpersonate = async () => {
        const isImpersonate =  new UserService().isImpersonated();
        isImpersonate.then(r => this.setState({isImpersonate: r}))
    }
    switchMenu = () => {
        this.setState(prevState =>({open: !prevState.open}), ()=>
            localStorage.setItem('close', JSON.stringify(!this.state.open))
        );

        this.props.onApply(this.state.open);
    }
    switchUser =  (email: string) => {
        const savedEmail = localStorage.getItem('email');
        if(savedEmail === email) {
            window.location.href = '/';
        } else {
            let switched =  new UserService().switchToAdOpsDashboard(email, this.props.currentNetwork);
            switched.then(() => {
                window.location.href = '/';
                    localStorage.setItem('email', email);
            });
        }
    }
    goToMyDashboard =  () => {
        let switched = new UserService().switchToPersonalDashboard();
        switched.then(() => {
            window.location.href = '/';
            localStorage.setItem('email', '');
            localStorage.setItem('currentPage', '0');
            localStorage.setItem('reportsPerPage', '10');
        });
    }
    isActiveClass = (email: string) => {
        let impersonatedEmail = localStorage.getItem('email');
        return email === impersonatedEmail;
    }
    render(){
        const {users, isImpersonate} = this.state;
        return (
            <>
                <div className={this.state.open ? 'nav open' : 'nav close'}>
                    <nav>
                        <div className="header-row logo-container">
                            <Link to={'/'} className={this.state.open ? '' : 'cropped'}>
                                <img src={logoPath} className={`logo ${this.state.open ? '' : 'cropped'}`} alt="WiredCarbon Logo" />
                            </Link>
                        </div>
                        <div className="dashboards">
                            {this.myDashboardButton()}
                            <div id='switch-user'>
                                <button className={isImpersonate ? 'title-as-btn active' : 'title-as-btn'} >
                                    <img src={switchIcon} alt=""/>
                                    <span>Switch User</span>
                                </button>
                                {this.dashboardsList(users, isImpersonate)}
                            </div>
                        </div>
                        {this.templateRepository()}
                        <UploadButton currentNetwork={this.props.currentNetwork} updateList={this.getTemplatesCategory}/>
                    </nav>
                    <button className='burger' style={{backgroundImage: `url(${menuIcon})`}} onClick={() => this.switchMenu()}>
                        {this.state.open ? <GrFormPrevious color={'#fff'} size={12}/> : <GrFormNext color={'#fff'} size={12 }/>}
                    </button>
                </div>
                <div className={this.state.open ? 'overlay open' : 'overlay close'}/>
            </>
        );
    }
    myDashboardButton = () => {
        return <Link to="#" className='title-as-btn my-dashboard' onClick={() => this.goToMyDashboard()} >
            {/*<FaUser size={22}/>*/}
            <img src={dashboardIcon} alt="My Dashboard"/>
            <span>My Dashboard</span>
        </Link>
    }
    dashboardsList = (users: Array<any>, isImpersonate: boolean) => {
        return <div className='dashboards-list'>
            <h4><img src={switchIcon} alt="switch"/> Switch User</h4>
            {users.map((user, index) =>
                <Link
                    className={isImpersonate ?
                        (this.isActiveClass(user.emailAddress) ? 'active' : '')
                        : ''}
                    onClick={() => this.switchUser(user.emailAddress)}
                    key={user + index}
                    to='#'>
                    {user.firstName} {user.lastName}
                    {this.isActiveClass(user.emailAddress) ? "'s Dashboard"  : ''}
                </Link>)
            }
        </div>
    }
    templateRepository = () => {
        return <div className="repository-container">
            <button className='title-as-btn'>
                <img src={templatesIcon} alt="Templates Repository"/>
                <span>Templates Repository</span>
            </button>
            {this.state.loaded ?
                <TemplatesList list={this.state.templatesList}/> :
                <Loader size={16}/>}
        </div>
    }
}
