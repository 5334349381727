import React, {useEffect, useState} from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { enGB } from 'react-date-range/src/locale'
import moment from 'moment';

import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {Constants} from "../../../lib/config/constants"; // theme css file

const endOfYesterday = moment().add(-1, 'days').endOf('day').toDate();

const sideBarOptions = () => [
    {
        label: 'Custom',
        type: 'STATIC',
        range: () => ({
            startDate: moment().startOf('day').add(12, 'hours').toDate(),
            endDate: moment().add(-1, 'hour').add(-1, "hours").toDate(),
        })
    },
    {
        label: 'Yesterday',
        type: 'YESTERDAY',
        range: () => ({
            startDate: moment().add(-1, 'days').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Last 7 days',
        type: 'LAST_7_DAYS',
        range: () => ({
            startDate: moment().add(-7, 'days').toDate(),
            endDate: endOfYesterday,
        })
    },

    {
        label: 'Last week',
        type: 'LAST_WEEK',
        range: () => ({
            startDate: moment().add(-1, 'week').startOf('isoWeek').toDate(),
            endDate: moment().add(-1, 'week').endOf('isoWeek').toDate(),
        })
    },
    {
        label: 'Last 30 days',
        type: 'LAST_30_DAYS',
        range: () => ({
            startDate: moment().add(-30, 'days').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Last month',
        type: 'LAST_MONTH',
        range: () => ({
            startDate: moment().add(-1, 'month').startOf('month').toDate(),
            endDate: moment().add(-1, 'month').endOf('month').toDate(),
        })
    },
    {
        label: 'Last 3 months',
        type: 'LAST_3_MONTHS',
        range: () => ({
            startDate: moment().add(-3, 'months').startOf('month').toDate(),
            endDate: moment().add(-1, 'months').endOf('month').toDate(),
        })
    },
    {
        label: 'Last 6 months',
        type: 'LAST_6_MONTHS',
        range: () => ({
            startDate: moment().add(-6, 'months').startOf('month').toDate(),
            endDate: moment().add(-1, 'months').endOf('month').toDate(),
        })
    },
    {
        label: 'Last year',
        type: 'LAST_YEAR',
        range: () => ({
            startDate: moment().add(-1, 'year').startOf('year').toDate(),
            endDate: moment().add(-1, 'year').endOf('year').toDate(),
        })
    },
    {
        label: 'Last 3 years',
        type: 'LAST_3_YEARS',
        range: () => ({
            startDate: moment().add(-3, 'year').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Last quarter',
        type: 'LAST_QUARTER',
        range: () => ({
            startDate: moment().add(-1, 'quarter').startOf('quarter').toDate(),
            endDate: moment().add(-1, 'quarter').endOf('quarter').toDate(),
        })
    },
    {
        label: 'Week to date',
        type: 'WEEK_TO_DATE',
        range: () => ({
            startDate: moment().startOf('isoWeek').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Month to date',
        type: 'MONTH_TO_DATE',
        range: () => ({
            startDate: moment().startOf('month').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Quarter to date',
        type: 'QUARTER_TO_DATE',
        range: () => ({
            startDate: moment().startOf('quarter').toDate(),
            endDate: endOfYesterday,
        })
    },
    {
        label: 'Year to date',
        type: 'YEAR_TO_DATE',
        range: () => ({
            startDate: moment().startOf('year').toDate(),
            endDate: endOfYesterday,
        })
    },
];

const DateRange = (props) => {
    const range = props.data.dateRange;
    const rangeType = props.type;
    let from = new Date();
    let to = new Date();
    if(range?.startDate) {
        from = new Date(range.startDate[0],range.startDate[1] - 1,range.startDate[2]);
        to = new Date(range.endDate[0],range.endDate[1] - 1,range.endDate[2]);
    }
    const [ state, setState ] = useState([
        {
            startDate: from,
            endDate: to,
            key: 'selection'
        }
    ]);
    const sideBar = sideBarOptions();

    let getRangeType = (date) => {
        let type = 'STATIC';
        for (let item = 0; item <= sideBar.length -1; item++) {
            if (moment(moment(sideBar[item].range().startDate).format()).isSame(moment(date.startDate).format()) &&
                moment(sideBar[item].range().endDate).format('YYYY-MM-DD') === moment(date.endDate).format('YYYY-MM-DD')) {
                type = sideBar[item].type;
            }
        }
        return type
    }
    const [type, setType] = useState(getRangeType(state[0]));

    useEffect(()=> {
        document.querySelectorAll('.rdrStaticRange')
            .forEach((item, index) => {
                if (rangeType === Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === item.firstChild.innerHTML)) {
                    item.setAttribute('id', 'active');
                }
                // undefined
                if (!Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === item.firstChild.innerHTML)) {
                    item.firstChild.innerHTML === 'Custom' &&
                    rangeType === 'STATIC' &&
                    item.setAttribute('id', 'active');
                }
                item.addEventListener(
                        ('click'), () => listener(item, index)
                    )
                }
            );
    }, []);
    let resetClass = buttonIndex => {

        document.querySelectorAll('.rdrStaticRange').forEach(button => button.setAttribute('id', ''));
        document.querySelectorAll('.rdrStaticRange')[buttonIndex].setAttribute('id', 'active');
    }
    let listener = (button, buttonIndex) => {
        if (rangeType === Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === button.firstChild.innerHTML)) {
            button.setAttribute('id', '');
        }
        resetClass(buttonIndex);
        if(button.firstChild.innerHTML && typeof(button.firstChild.innerHTML) === 'string') {
            if(Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === button.firstChild.innerHTML)) {
                button.setAttribute('id', 'active');
                setType(Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === button.firstChild.innerHTML));
            } else {
                if (!Object.keys(Constants.RANGES).find(key => Constants.RANGES[key] === button.firstChild.innerHTML)) {
                    button.setAttribute('id', 'active');
                    setType('STATIC')
                }
            }
        }
    }
    return (
        <div className='modal-wrapper'>
            <div className="modal-wrapper-content filters">
                <DateRangePicker
                    className={type !== 'STATIC' ? 'disabled': ''}
                    onChange={(item) => {
                        setState([
                            {
                                startDate: item.selection.startDate,
                                endDate: item.selection.endDate,
                                type: type,
                                key: 'selection',
                            }
                        ]);
                    }}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    maxDate={moment().toDate()}
                    direction="horizontal"
                    staticRanges={createStaticRanges(sideBar)}
                    inputRanges={[]}
                    locale={enGB}
                />
                <div className="modal-wrapper-content-controls">
                    <button onClick={() => {
                        props.getRange({...state[0], type})
                    }}>Apply</button>
                    <button onClick={() => {
                        props.onCancel();
                    }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};
export default DateRange;
