import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import NetworkService from "../lib/services/networkServices";
import CookieService from "../lib/services/cookieService";
import Header from './header';
import RoutesNav from "./routers/index";
import Menu from './menu';
import Login from "./pages/login";
import Splashscreen from "./splashscreen";
import UserService from "../lib/services/userService";
import GamAccess from "./pages/login/accessGam";
import ExtensionAccess from "./pages/login/accessExtension";
import './App.scss';
import './common.scss';

interface Props {

}

interface State {
    open?: boolean;
    isLoggedIn: boolean | null;
    isAdmin: boolean;
    networkIds: Array<string>;
    users: Array<any>;
    currentId: string;
    accessGAM: boolean | null,
    accessExtension: boolean | null,
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoggedIn: null,
            accessGAM: null,
            accessExtension: null,
            open: false,
            isAdmin: false,
            networkIds: [],
            users: [],
            currentId: localStorage.getItem('networkId') ?? '',
        }
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.onApply = this.onApply.bind(this);
    }
    async componentDidMount() {
        CookieService.setUuidCookie();
        new NetworkService().isLoggin()
            .then((result) => {
                this.setState({isLoggedIn: !!result},
                    async () => {
                    if(this.state.isLoggedIn) {
                        await new NetworkService().availableNetworkIds()
                            .then((result) => {
                                this.setState({isLoggedIn: !!result});
                                if(result) {
                                    this.setState({
                                        networkIds: result.map((item:any) => item.networkId),
                                        currentId: localStorage.getItem('networkId') ?
                                            localStorage.getItem('networkId') :
                                            result[0].networkId,
                                    }, async ()=> {
                                        if(this.state.networkIds.length) {
                                            await new UserService().checkAccessExtension(this.state.currentId)
                                                .then((result) => {
                                                    this.setState({accessExtension: result})
                                                });
                                            await new UserService().checkAccessGAM(this.state.currentId)
                                                .then((result) => {
                                                    if(result) {
                                                        this.setState({accessGAM: result}, async ()=> {
                                                            const users = await new UserService().getUsersList(CookieService.getCookie('uuid'), this.state.currentId);
                                                            this.setState({users});
                                                        })
                                                    }
                                                });
                                        }
                                    });
                                }
                            });
                    }
                });
            });
        new NetworkService().isAdmin()
            .then(res => {
                this.setState({isAdmin: res.adminStatus});
            });

    }
    onApply = (data:boolean) => {
        // @ts-ignore
        this.refs.main.onApply(data);
    }

    login() {
        this.setState({ isLoggedIn: true });
        localStorage.setItem('reportsPerPage', '10');
        localStorage.setItem('currentPage', '0');
    }
    logout() {
        const switched = new UserService().switchToPersonalDashboard();
        switched.then(()=> localStorage.setItem('email', ''));
        this.setState({ isLoggedIn: false });
        CookieService.setCookie("uuid", uuidv4(), 60);
        this.forceUpdate();
        localStorage.setItem('networkId', '');
        localStorage.setItem('reportsPerPage', '10');
        localStorage.setItem('currentPage', '0');
    }
    getCurrentNetworkId = (id: string) => {
        this.state.networkIds.length && this.setState({
            currentId: id,
        });
    }
    getLayout = (login: boolean | null, accessGam: boolean | null, accessExtension: boolean | null) => {
        switch (true) {
            case login === false: return <Login handleLogin={this.login}/>
            case (accessGam === false) : return <GamAccess logout={this.logout}/>
            case (accessExtension === false) : return <ExtensionAccess logout={this.logout}/>
            case (login && accessGam && accessExtension): return this.mainScreen()
            default: return <Splashscreen/>
        }
    }

    mainScreen = () => {
        return <>
            <main>
                <Menu
                    isAdmin={this.state.isAdmin}
                    onApply={this.onApply}
                    users={this.state.users}
                    currentNetwork={this.state.currentId}
                />
                <div className="wrapper">
                    <Header logout={this.logout} networkIds={this.state.networkIds} getId={this.getCurrentNetworkId}/>
                    <div className="content">
                        <main>
                            <RoutesNav
                                ref="main"
                                loginState={this.state.isLoggedIn}
                                handleLogout={this.logout}
                                onApply={this.onApply}
                                currentNetwork={this.state.currentId}
                            />
                        </main>
                    </div>
                </div>
            </main>
        </>
    }
    render(){
        const {isLoggedIn, accessGAM,accessExtension} = this.state;
      return (
          <div className='wrapper'>
              <Router>
                  {isLoggedIn ?
                      this.getLayout(isLoggedIn, accessGAM, accessExtension) :
                      <Login handleLogin={this.login}/>}
              </Router>
          </div>
      );
  }
}
export default withTranslation()(App)
