import React from "react";
import Utils from "../../../../lib/utils/utils";
import logo from './../../../../lib/assets/logo.svg';
import ReportItem from "./item";
import './styles.scss';

interface Props {
    reports: any,
    allowReportDelete: any,
}
export default function ReportsList({reports, allowReportDelete}: Props) {
    return (
        reports.length >= 1 ? reports.map((item: any, index: number) => {

            return <ReportItem item={item} key={item + index} allowReportDelete={allowReportDelete}/>
        }) : <div className='empty'>
            <h3>
                {Utils.translatedString('messages.noReports')}
            </h3>
            <h3>
                {Utils.translatedString('messages.goToTemplatesToAddReport')}
            </h3>
            <div className="logo" style={{backgroundImage: `url(${logo})`}}/>
        </div>
    );
};
