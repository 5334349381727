import React from "react";
import Utils from "../../../lib/utils/utils";
import {DOWNLOAD_PIVOT_TABLE_URL} from "../../../lib/config/urls";

interface Props {
    disabled: boolean,
    uuid: string,
    reportId: string,
}

export default function DownloadTableButton({disabled, uuid, reportId}: Props) {
    return (
        <a className={`download ${disabled ? 'disabled-link' : ''} with-icon`}
           href={ DOWNLOAD_PIVOT_TABLE_URL + `?uuid=${uuid}&reportId=${reportId}`}
        >
            {Utils.translatedString('controls.pivotTable')}
        </a>
    );
}
