import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources :any = {
    en: {
        translation: {
            templatesRepository: 'Templates Repository',
            switchUser: 'Switch User',
            hello: 'Howdy',
            networkId: 'Network Id',
            loading: 'Loading...',
            notFound: 'Page was not found',
            properties: {
                title: 'Report Properties',
                id: 'Report job ID',
                generatedDate: 'Date/Time generated',
                publisher: 'Publisher network name',
                user: 'User',
                type: 'Report type',
                range: 'Date Range',
                name: 'Name',
            },
            messages: {
                needToLogin: 'You need to be logged-in to use this extension',
                noAccess: 'Thank you for your interest in using the Wired Carbon GAM Reporting Module. It appears you currently do not have access to use this module. Please confirm with your system administrator that you have an active account. If you do not have an active account, please visit',
                noAccessSignUp: ' to sign up',
                orChangeAccount: 'or try to re-login using another Google account.',
                noAccessNetworkId: 'You are attempting to reach a Google Ad Manager that you don\'t have permission to. Please choose another Google account or contact your system administrator',
                overnightGeneration: 'Chart will be generated overnight and available for you tomorrow',
                waitingGeneration: 'Chart will be available once you generate the report (Button below)',
                chartPlaceholder: 'Your Report will appear here',
                noReports: 'There are no reports on your dashboard',
                goToTemplatesToAddReport: 'Please click TemplateRepository to begin adding reports to your dashboard',
                sureToDelete: 'Are you sure you want to delete this report?',
                editOrCreateReport: 'Would you like to edit this existing report or create a new one?',
                reportWillBeTomorrow: 'Your report will be generated overnight and available for you tomorrow.',
                editedReportWillBeTomorrow: 'Your report has been updated successfully and will be generated overnight and available for you tomorrow.',
                uploadError: 'Upload file error!',
                selectTemplateName: 'Template name:',
                selectTemplateCategory: 'Select template Category:',
                note: 'NOTE: Historical data is only available up to last 6 months with this report. You must create a different report with a longer filter duration if you wish to view data prior to this range.',
            },
            controls: {
                apply: 'Apply',
                cancel: 'Cancel',
                retry: 'Retry',
                removeFromDashboard: 'Remove from dashboard',
                remove: 'Remove',
                viewOrEdit: 'View/Edit',
                edit: 'Edit',
                createNew: 'Create New',
                generate: 'Generate report',
                downloadReport: 'Report',
                viewDataSource: 'Data source',
                pivotTable: 'Pivot Table',
                backToDashboard: 'Back to Dashboard',
                upload: 'Upload Template',
                login: 'Login',
                logout: 'Logout',
            }
        }
    },
    fr: {
        translation: {

        }
    },
};

i18n.use(initReactI18next).init({
    resources,

    lng: "en",

    interpolation: {
        escapeValue: false
    }
});

export default i18n;
