import React from "react";
import Utils from "../../../lib/utils/utils";
import {IoIosClose} from "react-icons/io";
interface Props {
    onClick: () => void,
    disabled: boolean,

}
export default function RemoveButton({onClick, disabled}: Props) {
    return (
        <button
            className='remove with-icon remove'
            onClick={onClick}
            disabled={disabled}
        >
            <span>
                {Utils.translatedString('controls.removeFromDashboard')}
            </span>
            <span className="btn-icon">
                <IoIosClose size={18}/>
            </span>
        </button>
    );
}
