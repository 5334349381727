import React from "react";
import Utils from "../../../lib/utils/utils";
import {DOWNLOAD_REPORT_URL} from "../../../lib/config/urls";

interface Props {
    uuid: string,
    reportId: string,
    disabled: boolean,

}
export default function SourceButton({uuid, reportId, disabled}: Props) {
    return (
        <a className={`download ${disabled ? 'disabled-link' : ''} with-icon`}
           href={ DOWNLOAD_REPORT_URL + `?uuid=${uuid}&reportId=${reportId}`}
        >
            {Utils.translatedString('controls.viewDataSource')}
        </a>
    );
}
